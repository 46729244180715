<template>
  <div class="page">
    <div class="banner">
      <div class="left">
        <h3>{{ $i18n.t('newsRoom.label') }}</h3>
        <p>{{ $i18n.t('newsRoom.describe') }}</p>
      </div>
      <div class="search-bar">
        <i class="el-icon-search search-icon"/>
        <input class="search-input" type="text" :placeholder="$i18n.t('news.searchPlaceholder')" v-model="queryParam.keyWords" @keyup.enter="search">
        <div class="search-button" @click="search">{{ $i18n.t('news.search') }}</div>
      </div>
    </div>
    <Breadcrumb title=""/>
    <div class="container" v-loading="loading">
      <el-col :span="24" style="margin: 20px 0px 10px 0px" class="news_item-col" v-for="(item,index) in newsData" :key="index">
        <div class="news_container">
          <img :src="domainUrl+item.imgSrc" style="" class="new_item_img"/>
          <div class="news_item">
            <h3 class="news_item__title" @click="goToDetail(item.id)">{{item.title}}</h3>
            <p class="news_item__publishDate">{{item.publishDate}}</p>
            <p class="news_item__summery">{{item.summary}}</p>
            <a class="news_item__btn" @click="goToDetail(item.id)">{{ $i18n.t('newsRoom.learnMore') }}</a>
          </div>
        </div>
        <el-divider></el-divider>
      </el-col>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import {listAll} from '@/api/news'
export default {
  name: "index",
  components: {
    Breadcrumb,
  },
  data() {
    return {
      newsData:[],
      loading: true,
      queryParam: {
        'keyWords': null,
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    search() {
      this.getList();
    },
    getList(){
      this.loading = true;
      listAll(this.queryParam).then(res=>{
        this.newsData = res.data.data
      }).finally(() => {
        this.loading = false;
      });
    },
    goToDetail(id) {
      let routeUrl = this.$router.resolve({
        path: `/news/${id}/detail`
      });
      window.open(routeUrl.href, '_blank');
    }
  }
}
</script>

<style scoped lang="less">
.banner {
  background-image: url(../../assets/image/news/banner.jpg);
  height: 320px;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  color: #FFFFFF;
  padding-top: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.15);
    pointer-events: none;
  }

  .left {
    z-index: 1;
    text-align: center;
    h3 {
      font-weight: normal;
      font-size: 40px;
      margin-bottom: 30px;
      color: #FFFFFF;
    }
    p {
      font-size: 20px;
      margin-bottom: 40px;
      color: #FFFFFF;
    }
  }
  .search-bar {
    width: 80%;
    max-width: 750px;
    background-color: #FFFFFF;
    height: 50px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.08);

    .search-icon {
      font-size: 24px;
      margin: 0 12px 0 24px;
      color: #999999;
      position: relative;
      z-index: 10;
    }

    .search-input {
      flex: 1;
      width: 100%;
      height: 50px;
      box-shadow: none;
      outline: none;
      font-size: 18px;
      font-family: "Microsoft Yahei", sans-serif;
      padding-left: 60px;
      margin-left: -55px;
      border-radius: 48px 0 0 48px;
      border: 1px solid transparent;
      position: relative;
      z-index: 3;
      color: #333333;

      &:focus + .search-icon {
        color: #c7000b;
      }
    }

    .search-button {
      cursor: pointer;
      color: #FFFFFF;
      font-size: 16px;
      background-color: var(--themeColor);
      height: 100%;
      width: 80px;
      padding-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      &:active {
        opacity: .8;
      }
    }
  }
}

.container {
  .news_container{
    display: flex;
    justify-content: center;
    align-items: center;
    .news_item {
      &__publishDate {
        padding: 15px 0px;
        color: #999;
      }

      &__summery {
        font-size: 16px;
      }

      &__title:hover {
        color: #c7000b;
        cursor: pointer;
      }

      &__btn {
        cursor: pointer;
        display: inline-block;
        border: 1px solid #333;
        padding: 10px 40px;
        border-radius: 5px;
        margin: 15px 0px;
        float: right;
      }

      &__btn:hover {
        background-color: #c7000b;
        color: #FFFFFF;
        border: 1px solid #c7000b;
      }
    }
    .new_item_img{
      width: 450px;
      height: 265px;
      padding-right: 20px;
    }
  }
}
@media (max-width: 991px) {
  .news_container{
    flex-direction: column;
    .new_item_img{
      padding: 0 40px !important;
    }
  }
}
</style>
